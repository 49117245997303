import React from 'react'
import {Link, StaticQuery} from 'gatsby'
import styled from 'styled-components'
import {slide as Menu} from 'react-burger-menu'

const Header = styled.header`
  background: ${props => props.theme.colors.base};
  width: 100%;
  padding: 1.5em 0;
  position: fixed;
  z-index: 2;
`
const Nav = styled.nav`
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0 auto;
  padding: 0 1.5em;

  ul {
    display: flex;
    justify-content: space-between;
  }

  li {
    display: inline-block;
    margin-left: 1em;
    &:first-child {
      position: relative;
      margin: 0;
      flex-basis: 100%;
    }
  }

  a {
    text-decoration: none;
    color: DarkGray;
    font-weight: 600;
    transition: all 0.2s;
    border-bottom: 2px solid ${props => props.theme.colors.base};
    white-space: nowrap;
    &:hover {
      color: white;
    }
  }
  .nav-item-res{
    display:none;
  }
  @media(min-width: 768px) {
    .nav-item-res{
      display: inline-block;
    }
    .bm-burger-button{
      display: none;
    }
  }
`

const activeLinkStyle = {
    color: 'white',
}

const burgerStyles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '36px',
    height: '30px',
    right: '36px',
    top: '16px'
  },
  bmBurgerBars: {
    background: '#fff'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top:0
  },
  bmMenu: {
    background: '#373a47',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em'
  },
  bmItem: {
    padding: '10px 0',
    borderBottom: 'none',
    fontSize: '1.5rem'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    width: '100vw',
    left: 0
  }
}

export default () => (
    <StaticQuery query={
        graphql`
            query tagQuery {
        allContentfulTags {
        edges {
            node {
              slug
              title
              post {
                id
              }
            }
          }
          }
      }
        `
    }
                 render={data => (
                     <Header>
                         <Nav id="navbar">
                             <ul>
                                 <li>
                                     <Link to="/" activeStyle={activeLinkStyle}>
                                         Home
                                     </Link>
                                 </li>
                                 {data.allContentfulTags.edges.map(edge =>
                                        <li className="nav-item-res">
                                         <Link to={"/tag/" + edge.node.slug + "/"} activeStyle={activeLinkStyle}>
                                             {edge.node.title}
                                         </Link>
                                         </li>
                                 )}
                                 <li className="nav-item-res">
                                 <Link to="/ueber/" activeStyle={activeLinkStyle}>
                                         Über
                                     </Link>
                                 </li>
                                 <li className="nav-item-res">
                                 <Link to="/kontakt/" activeStyle={activeLinkStyle}>
                                         Kontakt
                                     </Link>
                                 </li>
                              </ul>
                              <Menu styles={burgerStyles} right outerContainerId={"navbar"} className={"burgerButton"}>
                                 {data.allContentfulTags.edges.map(edge =>
                                         <Link to={"/tag/" + edge.node.slug + "/"} activeStyle={activeLinkStyle}>
                                             {edge.node.title}
                                         </Link>
                                 )}

                                     <Link to="/ueber/" activeStyle={activeLinkStyle}>
                                         Über
                                     </Link>
                                     <Link to="/kontakt/" activeStyle={activeLinkStyle}>
                                         Kontakt
                                     </Link>
                             </Menu>
                         </Nav>
                     </Header>
                 )}/>
);
